import { useState, useRef, useEffect } from "react";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { ButtonGroup } from "@blueprintjs/core/lib/esm/components/button/buttonGroup";
import { Divider } from "@blueprintjs/core/lib/esm/components/divider/divider";
import { Menu } from "@blueprintjs/core/lib/esm/components/menu/menu";
import { MenuItem } from "@blueprintjs/core/lib/esm/components/menu/menuItem";
import "../styles/ViewToolBar.css"
import { Popover2 } from "@blueprintjs/popover2/lib/esm/popover2";
import { useAppStore } from "../stores/AppStore"
import { useAuth } from "react-oidc-context"
import { ComparedSample, ComparedSampleAnalysis, Comparison, ComparisonWithAnalysis } from "../types/ComparisonTypes"
import { ComparisonAPI, SampleAPI } from '../api/DataAPI';
import { SampleMetadata } from "../types/SampleTypes";
import { H5 } from "@blueprintjs/core/lib/esm/components/html/html";
import { Classes } from "@blueprintjs/core/lib/esm/common";
import { ViewColumn } from "../types/ViewTypes";
import { useComparisonStore } from "../stores/ComparisonStore";
import { useUserStore } from "../stores/UserStore";
import { Toaster } from "../utils/Toaster";
import { downloadCSV } from "../api/dataExport";
import { useOpenComparisonsStore } from "../stores/OpenComparisonsStore";
import { DendrogramDistance, MultipleAlignment, ProcesstypeList, ViewDisplayed } from "../stores/ComparisonViewStore";
import { ComparisonForm } from "./ComparisonForm";
import { useOrganismStore } from "../stores/OrganismStore";
import { useAddToGroupDialogStore } from "../stores/AddToGroupDialogStore";
import { useDataViewerCtrStore } from "../stores/DataViewerCtrStore";
import { useDataViewerCtrFunctions } from "../hooks/useDataViewerCtrFunctions";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { Slider } from "@blueprintjs/core/lib/esm/components/slider/slider";
var merge = require('lodash.merge');
var dot = require('dot-object');

interface ComparisonToolBarProps {
  dendroRef: any
}

interface cellEditArray {
  value: string
  path: string
  rowIndex: number
  error?: string | undefined
}

interface sampleEditArray {
  path: string;
  rowIndex: number;
  sample: { [key: string]: any };
}

export function OpenComparisonToolBar({
  dendroRef
}: ComparisonToolBarProps) {
  const { setComparisons } = useComparisonStore()
  const { addFavoriteComparison, deleteFavoriteComparison } = useUserStore()
  const { setShowComparisonForm, selectedView } = useAppStore();
  const { setShowAddToGroupDialog } = useAddToGroupDialogStore()
  const { loadComparisonData, loadUPGMAData, sampleData, selectComparison } = useDataViewerCtrFunctions()
  const { tabs, selectedTabId, closeTab, setComparison, setUnsaved, setViewDisplayed, setAnalysesDisplayed, setSortedColumn, setShowSingleClickEdit,
    setSearchComparisonText, setAnalysesData, setAnalysesDetailError, setAnalysesLoading, setTableLoading, setCurrentTabName, getTabById, showGroupColors, setShowGroupColors, setMultipleAlignment, setMultipleAlignmentData, setProcessType, setBlockType, setMultipleAlignmentError,
    setAllProcessTypes, setIsSortContainerShowing, zoomValue, setShowZoomValue, setTabComparison
  } = useOpenComparisonsStore();
  const currentTab = getTabById(selectedTabId);
  const [saveAsPopoverIsOpen, setSaveAsPopoverIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const auth = useAuth();
  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore()
  const { selectedSampleIds } = useDataViewerCtrStore()
  const { setCellEdits, setNewEdits, setSampleData } = useOpenComparisonsStore()
  const { user } = useUserStore()
  const { sampleInfiniteQuery } = useDataViewerCtrFunctions()

  if (currentTab) {
    const { comparison, comparison: { comparedSamples }, analysesDisplayed, viewDisplayed, unsaved, searchComparisonText, filteredSortedSampleIds, cellEdits, showSingleClickEdit, multipleAlignment, multipleAlignmentData, processType, blockType, allProcessTypes } = currentTab


    const analysesList = organization?.organizationName === 'CaliciNet' ? ['SEQ_RegB-GI', 'SEQ_RegB-GII', 'SEQ_RegC-GI', 'SEQ_RegC-GII', 'SEQ_RegB-C-GI', 'SEQ_RegB-C-GII'] : ['quality', 'wgmlst', 'plasmids', 'resistance']
    const multipleAlignmentList: MultipleAlignment[] = ['Sequences', 'Consensus_Representations']
    const processtypeList: ProcesstypeList[] = ["SEQ_RegB-GI", "SEQ_RegB-GII", "SEQ_RegC-GI", "SEQ_RegC-GII", "SEQ_RegB-C-GI", "SEQ_RegB-C-GII"]

    if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
      throw new Error("No authenticated user found.")
    const comparisonAPI = new ComparisonAPI(auth.user?.access_token ?? "")
    const sampleAPI = new SampleAPI(auth.user?.access_token ?? "");

    const onDelete = async (selectedSampleIds: string[]) => {
      const updatedComparison = {
        ...comparison,
        comparedSamples: comparedSamples.filter(v => !selectedSampleIds.includes(v.sampleId))
      };
      setComparison(updatedComparison)
      setUnsaved(true)
      loadComparisonData(updatedComparison, analysesDisplayed, selectedTabId)
      if (organization?.organizationName !== 'CaliciNet')
        loadUPGMAData(updatedComparison, selectedTabId)
    }

    const getFilteredComparisonCount = (searchComparisonText: string) => {
      if (currentTab.sampleData) {
        return currentTab.sampleData.map(v => dot.dot(v))
          .filter(v => {
            if (searchComparisonText && searchComparisonText.trim() !== "")
              if (typeof (v) === "object")
                return Object.entries(v as object).filter(([k, v]) => {
                  if (comparison?.columns.map(a => a.path)?.includes(k))
                    return typeof (v) === "string" && v.toLowerCase().includes(searchComparisonText.toLowerCase())
                  else
                    return false
                }).length > 0
              else
                return false
            else
              return true
          }).length
      } else {
        return false
      }
    }

    //check if a comparison with the same name already exists
    const checkNameOfComparison = async (name: string) => {
      const allComparisons = await comparisonAPI.getComparisonsByOrganism(organism!.name)
      if (allComparisons.find(comparison => comparison.name.toLowerCase() === name.toLowerCase())) {
        return allComparisons.find(comparison => comparison.name.toLowerCase() === name.toLowerCase());
      }
      return false;
    }

    const addSelectedSamples = async () => {
      const addedComparedSamples = selectedSampleIds
        //filter samples that are already included in comparison
        .filter(sampleId => !comparedSamples.find(comparedSample => comparedSample.sampleId === sampleId))
        //conver sampleId's to compared samples
        .map(sampleId => ({
          sampleId,
          sampleKey: sampleData.filter(sample => sample.id === sampleId)[0].data.metadata.Key
        } as ComparedSample))
      const updatedComparison = {
        ...comparison,
        comparedSamples: [...comparedSamples, ...addedComparedSamples]
      }
      setComparison(updatedComparison)
      setUnsaved(true)
      loadComparisonData(updatedComparison, analysesDisplayed, selectedTabId)
      if (organization?.organizationName !== 'CaliciNet')
        loadUPGMAData(updatedComparison, selectedTabId)
    }

    if (!organism)
      throw (new Error("Organism is not defined."))

    const saveCSV = (data: string) => {
      if (data === "Metadata") {
        var headerNames;
        var dataPath;
        // header
        if (comparison.id) {
          headerNames = (comparison?.columns.map(v => v.title));
        } else {
          //when new comparison is selected, display all columns
          const fieldList = Object.entries(organism.properties).map(([k, v], i) => ({ path: v.path, name: k, title: v.title ? v.title : k, required: v.requiredInViews }) as ViewColumn)
          headerNames = fieldList.map(v => v.title)
        }

        // //Make sure that all 'headerNames'
        // //1. do not include spaces (' ') to avoid the 'TooManyFields' CSV error
        // //2. should match with 'pulsenetFields.properties' to display data appropriately.
        let mappedHeaderNames = [];

        if (headerNames) {
          for (let headerName of headerNames) {
            for (let property in organism.properties) {
              if (organism.properties[property].title === headerName || property === headerName) {
                // If 'title' exists or if property name itself matches, map it to the property name
                mappedHeaderNames.push(property);
                break;
              }
            }
          }
        }

        // sample data path
        if (comparison.id) {
          dataPath = comparison?.columns.map(v => v.path);
        } else {
          //when new comparison is selected, display all columns
          const fieldList = Object.entries(organism.properties).map(([k, v], i) => ({ path: v.path, name: k, title: v.title ? v.title : k, required: v.requiredInViews }) as ViewColumn)
          dataPath = fieldList.map(v => v.path)
        }

        let flatSampleData;
        if (currentTab.sampleData)
          flatSampleData = currentTab.sampleData.map(v => dot.dot(v))

        downloadCSV(flatSampleData, dataPath, mappedHeaderNames, 'export.csv');
      } else if (data === "Analyses") {
        if (currentTab.analysesData) {
          const flattenedData = currentTab.analysesData.comparedData.map(v => {
            const { analysisData, ...restOfProperties } = v;

            return {
              ...restOfProperties,
              ...(currentTab.analysesDisplayed === "wgmlst" && comparison.mlstScheme && comparison.mlstScheme !== "wgmlst" && organism.MLSTSchemes[comparison.mlstScheme] 
                ? Object.fromEntries(Object.entries(analysisData).filter(([k]) => organism.MLSTSchemes[comparison.mlstScheme!].includes(k)))
                : analysisData)
            };
          });
          var mappedHeaderNames = getComparedDataColumns()
          mappedHeaderNames.unshift('sampleKey')
          downloadCSV(flattenedData, mappedHeaderNames, mappedHeaderNames, 'export.csv');
        }
      } else if (data === "DistanceMatrix") {
        const dMatrix = currentTab.comparison.distanceMatrix
        const exportArray = []
        const leafOrder = currentTab.comparison.leafOrder || []
        if (!leafOrder.includes('samples')) leafOrder.unshift('samples')
        if (dMatrix) {
          let j = 0
          for (let row of dMatrix) {
            let i = 0
            let rowObj: any = {}
            rowObj['samples'] = leafOrder[j + 1]
            for (let el of row) {

              rowObj[leafOrder[i + 1]] = el
              i++
            }
            exportArray.push(rowObj)
            j++
          }
        }
        const fileName = `${currentTab.name.replace(/ /g, "_")}-DistanceMatrix.csv`
        downloadCSV(exportArray, leafOrder, leafOrder, fileName)
      }
    }

    const saveTXT = () => {
      const element = document.createElement("a");
      const file = new Blob([comparison.newick || ""], {
        type: "text/plain"
      });
      element.href = URL.createObjectURL(file);
      element.download = "newick.txt";
      document.body.appendChild(element);
      element.click();
    }

    const saveFASTQA = () => {
      if(multipleAlignmentData){
        let fastaContent
        if(multipleAlignment=== "Sequences"){
          fastaContent = Object.entries(multipleAlignmentData.sequences)
          .map(([header, sequence]) => `>${header}\n${sequence}`)
          .join("\n\n")
        } else if(multipleAlignment=== "Consensus_Representations"){
          fastaContent = Object.entries(multipleAlignmentData.consensus_representations)
          .map(([header, consensus]) => `>${header}\n${consensus}`)
          .join("\n\n")
        }

        if(fastaContent){
          const element = document.createElement("a")
          const file = new Blob([fastaContent], {
            type: "text/plain"
          })
          element.href = URL.createObjectURL(file)
          element.download = multipleAlignment === 'Sequences' ? "sequences_alignment.fasta" : "consensus_alignment.fasta"
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
        }
      }
    }

    const importNWK = () => {
      const inputElement = document.createElement("input")
      inputElement.type = "file"
      inputElement.accept = ".nwk"
  
      inputElement.addEventListener("change", async (event: Event) => {
        const target = event.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          if (!file.name.endsWith(".nwk")) {
            alert("Please select a valid .nwk file")
            return
          }

          const fileReader = new FileReader()
          fileReader.onload = (e) => {
            const fileContent = e.target?.result as string  
            // Extract the sample identifiers (leafOrder) using regex
            const extractedLeafOrderArray = fileContent.match(/(?<=\(|,)[^(),:]+(?=:)/g) || []
            const extractedLeafOrder = Array.from(new Set(extractedLeafOrderArray)); // Remove duplicates

            // Check if only the sample identifiers from the comparison are contained
            const delimiter = '_'
            const comparedSampleMetadataKeys = sampleData.filter(v => comparedSamples.map(v => v.sampleId).includes(v.id)).map(v => v.data.metadata.Key)
            const missingSamples = extractedLeafOrder.filter(key => {
              // Extract the part of the key before the last delimiter
              const keyPrefix = key.substring(0, key.lastIndexOf(delimiter));
              
              return !comparedSampleMetadataKeys.some(metadataKey => 
                metadataKey !== null && keyPrefix.includes(metadataKey.toString())
              )
            })

            if (missingSamples.length > 0) {
              // invalid
              alert("Only sample identifiers from the comparison are allowed.")
            } else {
              // valid
              setTabComparison(selectedTabId, {
                ...comparison,
                leafOrder: extractedLeafOrder,
                newick: fileContent
              })
              onClick_viewDisplayed("Dendrogram")
            }
          };
          fileReader.readAsText(file);
        }
      });
      inputElement.click();   
    }

    const getComparedDataColumns = () => {
      const columns: string[] = [];
      const currentTab = getTabById(selectedTabId)
      if (!currentTab?.analysesData)
        return []

      const { analysesData } = currentTab

      analysesData.comparedData.forEach((data: ComparedSampleAnalysis) => {
        const analysisData = data.analysisData
        const filteredAnalysisData = currentTab.analysesDisplayed === "wgmlst" && comparison.mlstScheme && comparison.mlstScheme !== "wgmlst" && organism.MLSTSchemes[comparison.mlstScheme] 
                ? Object.fromEntries(Object.entries(analysisData).filter(([k]) => organism.MLSTSchemes[comparison.mlstScheme!].includes(k)))
                : analysisData

        if (filteredAnalysisData) {
          Object.keys(filteredAnalysisData).forEach((key) => {
            if (!columns.includes(key)) {
              columns.push(key)
            }
          });
        }
      });
      return columns
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const onclick_confirmSaveAs = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      try {
        //check if a comparison with the same name already exists
        const duplicateCheck = await checkNameOfComparison(inputValue)
        if (duplicateCheck && duplicateCheck.id) {
          if (window.confirm("A comparison with this name already exists. Would you like to overwrite it?")) {
            await comparisonAPI.delete(duplicateCheck.id);
            deleteFavoriteComparison(duplicateCheck.id, auth.user?.access_token ?? "");
            const openTab = tabs.find(v => duplicateCheck.id === v.comparison.id)
            if (openTab)
              closeTab(openTab.id)
          }
          else {
            setError(true)
            return
          }
        } else {
          setError(false);
        }

        setLoading(true);

        if (!comparison.id) {
          const updatedComparison = { ...comparison, name: inputValue }
          updatedComparison.organism = organism?.name ?? "";
          updatedComparison.organization = organism?.organization ?? "";
          const savedComparison = (await comparisonAPI.insert(updatedComparison))
          setComparisons(await comparisonAPI.getComparisonsByOrganism(organism!.name))
          //add to Favorites
          if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
            throw new Error("No authenticated user found.")

          if (savedComparison.id) {
            await addFavoriteComparison(savedComparison.id, auth.user?.access_token ?? "", organism.name, organization?.organizationName);
          }
          setComparison(savedComparison);
          setCurrentTabName(savedComparison.name)
        }

        Toaster.show({ icon: "tick", message: `Comparison Saved Successfully`, intent: "success" })
        setUnsaved(false)

      } catch (e) {
      }
      setLoading(false);
    }

    const getComparedDataList = async (analysesName: string) => {
      const comparedSampleAnalyses = await sampleAPI.GetAllAnalysesByComparedSamples(comparedSamples)
      setAllProcessTypes([...new Set(comparedSampleAnalyses.map(v => v.processName ?? "").filter(p => p))])
      const updatedComparedSampleAnalyses = comparedSampleAnalyses.map(v => ({
        ...v,
        analysisData: {
          "region": v.processName,
          ...v.analysisData
        }
      }))
      return {
        processName: analysesName,
        comparedData: updatedComparedSampleAnalyses.filter(v => isMatch(analysesName, v.processName ?? ""))
      } as ComparisonWithAnalysis

    }

    const onClick_analysesMenuItem = async (analysesName: string) => {
      setAnalysesDisplayed(`${analysesName}`)
      setProcessType(`${analysesName}` as ProcesstypeList)
      setAnalysesDetailError("");
      setAnalysesLoading(true)
      try {//Load Analyses Data
        const currentTab = getTabById(selectedTabId)
        if (!currentTab)
          return

        const analysesData = organization?.organizationName === "CaliciNet" ? await getComparedDataList(analysesName) : await sampleAPI.GetAnalysesByComparedSamples(currentTab.comparison.comparedSamples, analysesName);
        if (analysesData) {
          setAnalysesData(analysesData)
        }

        else
          throw (new Error("No analyses data could not be found."))
      }
      catch (e: any) {
        setAnalysesDetailError(`There was an error loading the analyses data: ${e.message}`)
        Toaster.show({ icon: "error", message: `There was an error loading the analyses data.`, intent: "danger" })
      }
      setAnalysesLoading(false)
    }

    const onClick_viewDisplayed = ((viewDisplayed: ViewDisplayed) => {
      setSortedColumn(undefined)
      setViewDisplayed(viewDisplayed)
    })

    const onClick_dendrogramDistance = async (dendrogramDistance: DendrogramDistance) => {
      setUnsaved(true)
      const updatedComparison = {
        ...comparison,
        dendrogramDistance
      }
      setComparison(updatedComparison)
      await loadUPGMAData(updatedComparison, selectedTabId)
    }

    const saveComparison = async () => {
      try {
        await comparisonAPI.update(comparison.id, comparison)
        Toaster.show({ icon: "tick", message: `Comparison Saved Successfully`, intent: "success", timeout: 2000 })
      }
      catch (e: any) {
        Toaster.show({ icon: "error", message: `There was an error saving the comparison. Check the console for more details.`, intent: "danger" })
      }
      setUnsaved(false)
    }

    const onClick_saveComparison = async () => {
      setLoading(true);
      await saveComparison()
      setLoading(false)
    }

    const onClick_editComparison = () => {
      setShowComparisonForm(true)
      setTimeout(() => {
        let element = document.getElementsByClassName('comparison-form')?.[0]?.parentElement?.parentElement
        if (element !== null && element !== undefined) {
          element.style.zIndex = '21'
        }
      }, 500);
    }

    const onClick_addSelectedSamples = () => {
      addSelectedSamples()
    }

    const onClick_removeFromGroup = () => {
      const { comparedSamples } = comparison

      const updatedComparedSamples = comparedSamples.map(comparedSample => {
        const isIncluded = selectedSampleIds.includes(comparedSample.sampleId);
        return {
          ...comparedSample,
          groupName: isIncluded ? "" : comparedSample.groupName
        };
      });
      setComparison({
        ...comparison,
        comparedSamples: updatedComparedSamples
      })
      setUnsaved(true)
    }

    const getSelectedSampleIdsInComparison = () => selectedSampleIds.filter(sampleId => comparedSamples.find(v => v.sampleId === sampleId))
    const getSelectedSampleIdsNotInComparison = () => selectedSampleIds.filter(sampleId => !comparedSamples.find(v => v.sampleId === sampleId))

    const isInGroups = () => comparison.comparedSamples?.filter(v => v.groupName !== undefined && v.groupName !== "")?.some(v => selectedSampleIds.includes(v.sampleId)) ?? false

    const onClick_mlstScheme = async (mlstScheme: string) => {
      setUnsaved(true)
      setComparison({ ...comparison, mlstScheme })
      await loadUPGMAData({ ...comparison, mlstScheme }, selectedTabId)
    }

    const showSaveEditAlert = () => {
      setShowSingleClickEdit(true)
    }

    const saveCellEdits = async () => {
      try {
        const { comparedSamples } = comparison
        if (filteredSortedSampleIds) {
          const sortedComparedSamples = filteredSortedSampleIds.map(id => {
            return comparedSamples.find(v => v.sampleId === id);
          }).filter((sample): sample is ComparedSample => sample !== undefined);
          const cellEditArray = Object.entries(cellEdits).map(v => v[1])
          if (cellEditArray.filter(v => v.error).length > 0 && !window.confirm("Some changes are invalid and will not take effect. Would you like to continue saving?"))
            return
          setLoading(true)
          const metadataArray = await createMetadataArray(cellEditArray, sortedComparedSamples)
          const sampleEditArray = await createSampleEditArray(cellEditArray, sortedComparedSamples)
          const updatedSampleEditArray = await updateSampleDetails(sortedComparedSamples, sampleEditArray, sampleAPI)

          if (metadataArray.length > 0) {
            if (user?.role.includes("DatabaseManager"))
              await Promise.all(metadataArray.map(v => sampleAPI.updateNDBMetadata(v.sampleId, v.metadata)))
          }
          if (updatedSampleEditArray.length > 0)
            await Promise.all(updatedSampleEditArray.map(v => sampleAPI.update(v.id, v)))
          //update sample data in the comparison view  
          const updatedSampleData = currentTab.sampleData?.map(v => {
            if (metadataArray.length > 0 && user?.role.includes("DatabaseManager"))
              metadataArray.forEach(v2 => {
                if (v.id === v2.sampleId)
                  return v.data.metadata = v2.metadata
              })
            if (updatedSampleEditArray.length > 0)
              updatedSampleEditArray.forEach(v2 => {
                if (v.id === v2.id)
                  return v.data.metadata = v2.data.metadata
              })
            return v;
          }
          )
          if (updatedSampleData)
            setSampleData(updatedSampleData)
          if (metadataArray.length > 0 && !user?.role.includes("DatabaseManager"))
            Toaster.show({ icon: "warning-sign", message: `Your current role does not have permission to edit national database samples.`, intent: "warning" })
        }
      } catch (e: any) {
        console.error("Error saving cell edits: ", e.message)
        Toaster.show({ icon: "error", message: `There was an error saving cell edits. Check the console for more details.`, intent: "danger" })
      }

      setCellEdits({})
      setNewEdits([]);
      //update sample data in the lab or ndb view 
      sampleInfiniteQuery.remove();
      await sampleInfiniteQuery.refetch();
      setLoading(false)
    }

    const createMetadataArray = async (cellEditArray: cellEditArray[], sortedComparedSamples: ComparedSample[]) => {
      return cellEditArray.filter(v => !v.error && sortedComparedSamples[v.rowIndex].nationalDatabase)
        .map(v => ({
          sampleId: sortedComparedSamples[v.rowIndex].sampleId,
          nationalDatabase: true,
          metadata: { [v.path.replace("data.metadata.", "")]: v.value }
        }))
        .reduce((pv, cv) => {
          if (pv.map(v => v.sampleId).includes(cv.sampleId))
            pv[pv.map(v => v.sampleId).indexOf(cv.sampleId)].metadata = { ...pv[pv.map(v => v.sampleId).indexOf(cv.sampleId)].metadata, ...cv.metadata }
          else
            pv.push(cv)
          return pv
        }, [] as { sampleId: string, nationalDatabase: boolean, metadata: SampleMetadata }[]);
    }

    const createSampleEditArray = async (cellEditArray: cellEditArray[], sortedComparedSamples: ComparedSample[]) => {
      return cellEditArray.filter(v => !sortedComparedSamples[v.rowIndex].nationalDatabase)
        .reduce((pv, cv) => {
          if (cv.error)
            return pv
          const index = pv.map(v => v.rowIndex).indexOf(cv.rowIndex)
          if (index > -1)
            pv[index].sample[cv.path] = cv.value
          else
            pv = [...pv, { path: cv.path, rowIndex: cv.rowIndex, sample: { [cv.path]: cv.value } }]
          return pv
        }, [] as sampleEditArray[]);
    }

    const updateSampleDetails = async (sortedComparedSamples: ComparedSample[], sampleEditArray: sampleEditArray[], sampleAPI: SampleAPI) => {
      return Promise.all(
        sampleEditArray.map(async v => {
          const sampleDetails = await sampleAPI.get(sortedComparedSamples[v.rowIndex].sampleId);
          return merge(sampleDetails, dot.object(v.sample));
        })
      );
    }

    const onclick_addNewComparison = () => {
      if (!organism)
        throw new Error("Organism is not defined.")
      const comparison = {
        dendrogramAlgorythm: "upgma",
        dendrogramDistance: "absolute_allele_differences",
        mlstScheme: "wgmlst",
        //Add all columns as default
        columns: Object.entries(organism.properties).map(([k, v]) => ({ path: v.path, name: k, title: v.title ? v.title : k, required: v.requiredInViews }) as ViewColumn),
        comparedSamples: (getSelectedSampleIdsInComparison() ?? []).map((sampleId: string) => ({
          sampleId,
          sampleKey: comparedSamples.find(sample => sample.sampleId === sampleId)?.sampleKey,
          nationalDatabase: selectedView?.nationalDatabase
        } as ComparedSample))
      } as Comparison
      selectComparison(comparison)
    }

    const onClick_multipleAlignmentMenuItem = async (multipleAlignment: MultipleAlignment) => {
      setMultipleAlignment(multipleAlignment)
      if (multipleAlignment === "Consensus_Representations") {
        setBlockType("diff")
        await loadMultipleAlignmentData(comparedSamples, processType, "diff")
      } else {
        await loadMultipleAlignmentData(comparedSamples, processType, blockType)
      }
    }



    const onClick_processType = async (processName: ProcesstypeList) => {
      setProcessType(processName)
      setAnalysesLoading(true)
      await loadMultipleAlignmentData(comparedSamples, processName, blockType)
      const analysesData = await getComparedDataList(processName)
      if (analysesData) {
        setAnalysesData(analysesData)
      }
      setAnalysesLoading(false)
    }

    const onClick_blockType = async (blockType: 'diff' | null) => {
      setBlockType(blockType)
      await loadMultipleAlignmentData(comparedSamples, processType, blockType)
    }

    const loadMultipleAlignmentData = async (comparedSamples: ComparedSample[], processType: ProcesstypeList, blockType: 'diff' | null) => {
      setMultipleAlignmentError("");
      setTableLoading(true);
      try {
        // Load multiple alignment data   
        const alignmentRequest = comparedSamples.map(comparedSample => {
          return {
            sampleId: comparedSample.sampleId,
            sampleKey: comparedSample.sampleKey, //sampleData.filter(sample => sample.id === comparedSample.sampleId)[0].data.metadata.Key as string,
            nationalDatabase: comparedSample.nationalDatabase,
            groupName: comparedSample.groupName,
            multipleProcesses: allProcessTypes?.filter(p => isMatch(processType, p))
          }
        })

        const multipleAlignmentData = await sampleAPI.GetAlignmentByComparedSamples(alignmentRequest, blockType);
        setMultipleAlignmentData(multipleAlignmentData)
      } catch (e) {
        console.log("error", e)
        setMultipleAlignmentError(`There was an error loading the multiple alignment data.`);
        Toaster.show({ icon: "error", message: "There was an error loading the multiple alignment data.", intent: "danger" });
      }
      setTableLoading(false);
    }

    return <div className="toolBar">
      <div style={{ height: loading ? "34px" : "" }}>
        {loading ? <div style={{ marginLeft: "10px", marginTop: "4px" }}><Spinner size={25}></Spinner></div> :
          <ButtonGroup minimal={true}>
            {!comparison.id &&
              <Popover2 content={
                <div style={{ padding: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className={Classes.LABEL}>
                      <H5>Comparison Name</H5>
                      <input alt="input field for comparison name" autoFocus={true} className={Classes.INPUT} ref={inputRef} onChange={e => setInputValue(e.target.value)} type="text" />
                      {error ? <span style={{ color: "red" }}>The specified name already exists.</span> : null}
                    </label>
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end", marginRight: -10 }}>
                    <Button aria-label="confirm changes" disabled={!(inputValue && inputValue.trim() !== '')} intent={Intent.PRIMARY} className={Classes.POPOVER_DISMISS}
                      onClick={onclick_confirmSaveAs}
                    >
                      Confirm
                    </Button>
                    <Button aria-label="cancel changes and exit window" className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }} onClick={(e) => {
                      e.preventDefault();
                      setSaveAsPopoverIsOpen(false);
                      setError(false);
                    }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              } placement="top-start" modifiers={{ arrow: { enabled: false } }} isOpen={saveAsPopoverIsOpen}>
                <Button aria-label="save changes into new comparison" className='bp4-intent-primary bp4-icon-floppy-disk' onClick={() => { setSaveAsPopoverIsOpen(true) }}>Save As</Button>
              </Popover2>
            }
            {unsaved && comparison.id && <Button aria-label="save changes to current comparison" className='bp4-intent-primary bp4-icon-floppy-disk' onClick={() => onClick_saveComparison()}>Save</Button>}
            <Popover2 content={
              <Menu>
                {getSelectedSampleIdsNotInComparison().length > 0 && <MenuItem icon="add"
                  text={`Add ${getSelectedSampleIdsNotInComparison().length} Selected Sample${getSelectedSampleIdsNotInComparison().length > 1 ? 's' : ''}`} onClick={() => onClick_addSelectedSamples()} />}
                {organization?.organizationName !== 'CaliciNet' && <MenuItem aria-label="export distance matrix as spreadsheet" icon="export" text="Export Distance Matrix" onClick={() => saveCSV("DistanceMatrix")} />}
                <MenuItem aria-label="export analysis as spreadsheet" icon="export" text="Export Analyses" onClick={() => saveCSV("Analyses")} />
                <MenuItem aria-label="export metadata as spreadsheet" icon="export" text="Export Metadata" onClick={() => saveCSV("Metadata")} />
                {organization?.organizationName !== 'CaliciNet' && <MenuItem aria-label="export newick tree as text file" icon="export" text="Export Newick Tree" disabled={comparison.newick ? false : true} onClick={() => saveTXT()} />}
                {organization?.organizationName !== 'CaliciNet' && <MenuItem aria-label="export dendrogram as PNG image" icon="export" text="Export Dendogram PNG" disabled={comparison.newick ? false : true} onClick={() => setIsSortContainerShowing(true)} />}
                {organization?.organizationName === 'CaliciNet' && <MenuItem aria-label="export multiple alignment" icon="export" text="Export Multiple Alignment" disabled={multipleAlignmentData ? false : true} onClick={saveFASTQA} />}
                {organization?.organizationName === 'CaliciNet' && <MenuItem aria-label="import nwk file" icon="import" text="Import NWK File" onClick={importNWK} />}
                <MenuItem aria-label="edit comparison settings" icon="edit" text="Edit Comparison" onClick={() => onClick_editComparison()} />
                <MenuItem aria-label="open comparison as full page" icon="open-application" text="Pop Out" onClick={() => {
                  const searchParams = new URL(window.location.href).search;
                  localStorage.setItem("selectedSampleIds", JSON.stringify(selectedSampleIds))
                  localStorage.setItem("comparedSamples", JSON.stringify(comparedSamples))

                  const popupsOpen = Number(localStorage.getItem("popupsOpen") || 0)
                  localStorage.setItem("popupsOpen", `${popupsOpen + 1}`)

                  closeTab(selectedTabId)
                  if (searchParams) {
                    window.open(`${window.location}&fullComparison=true`, `popup${popupsOpen}`, "height=800,width=800")
                  } else {
                    window.open(`${window.location}?fullComparison=true`, `popup${popupsOpen}`, "height=800,width=800")
                  }

                }} />
                <MenuItem aria-label="set show/hide group colors" icon={showGroupColors ? "eye-open" : "eye-off"} text={showGroupColors ? "Hide Group Colors" : "Show Group Colors"} disabled={viewDisplayed === "Dendrogram" || viewDisplayed === "Distance Matrix" ? false : true} onClick={() => setShowGroupColors(!showGroupColors)} />
                {
                  showSingleClickEdit
                  ? <MenuItem aria-label="exit single click edit mode" icon="cross" text="Exit Single-Click Edit" onClick={() => setShowSingleClickEdit(false)} intent="danger" />
                  : comparedSamples.some(v => v.nationalDatabase)
                    ? null
                    : <MenuItem aria-label="activate single click edit mode" icon="grid" text="Single-Click Edit" onClick={() => showSaveEditAlert()} />
                }
              </Menu >
            } fill={true} minimal placement="bottom-start" >
              <Button
                aria-label="open comparison actions menu"
                alignText="left"
                fill={true}
                icon="menu"
                rightIcon="caret-down"
                text="Options"
              />
            </Popover2 >
            <Popover2 content={
              <Menu>
                <MenuItem aria-label="open analyses view" text="Analyses" onClick={() => onClick_viewDisplayed("Analyses")} />
                <MenuItem aria-label="open dendrogram view" text="Dendrogram" onClick={() => onClick_viewDisplayed("Dendrogram")} />
                {organization?.organizationName !== 'CaliciNet' && <MenuItem aria-label="open distance matrix view" text="Distance Matrix" onClick={() => onClick_viewDisplayed("Distance Matrix")} />}
                {organization?.organizationName === 'CaliciNet' && <MenuItem text="Multiple Alignment" onClick={() => { onClick_viewDisplayed("Multiple Alignment"); onClick_multipleAlignmentMenuItem(multipleAlignmentList[0]); }} />}
              </Menu>
            } fill={true} minimal placement="bottom">
              <Button
                aria-label="open view actions menu"
                alignText="left"
                fill={true}
                icon="applications"
                rightIcon="caret-down"
                text={`View: ${viewDisplayed}`}
              />
            </Popover2>
            {
              ((viewDisplayed === "Analyses" && analysesDisplayed === "wgmlst") || viewDisplayed === "Dendrogram" || viewDisplayed === "Distance Matrix") && <Popover2 content={
                <Menu>
                  {organism?.MLSTSchemes
                    ? ['wgmlst', ...Object.keys(organism.MLSTSchemes)]
                      .map(k => <MenuItem key={k} text={k} onClick={() => onClick_mlstScheme(k)} />)
                    : null
                  }
                </Menu>
              } fill={true} minimal placement="bottom">
                <Button
                  aria-label="open MLST scheme menu"
                  alignText="left"
                  fill={true}
                  icon="array"
                  rightIcon="caret-down"
                  text={`MLST Scheme: ${comparison.mlstScheme}`}
                  title="MLST Scheme"
                />
              </Popover2>
            }
            {
              viewDisplayed === "Analyses" && analysesDisplayed !== undefined && analysesList &&
              <Popover2 content={
                <Menu>
                  {analysesList.map(analyses => (
                    <MenuItem key={`${analyses}`} text={`${analyses}`} onClick={() => onClick_analysesMenuItem(analyses)} />
                  ))}
                </Menu>
              } fill={true} minimal placement="bottom">
                <Button
                  aria-label="open analyses menu"
                  alignText="left"
                  fill={true}
                  icon="list"
                  rightIcon="caret-down"
                  text={`${analysesDisplayed}`}
                />
              </Popover2>
            }
            {
              viewDisplayed === "Analyses" && <>
                <Divider />
                <div className="bp4-input-group .modifier">
                  <span className="bp4-icon bp4-icon-search"></span>
                  <input alt="search comparison input field" id="viewSearchBar" type="text" className="bp4-input " style={{ margin: 2 }} placeholder="Search" defaultValue={searchComparisonText} onKeyUp={(e) => {
                    if (e.key === "Enter")
                      setSearchComparisonText(e.currentTarget.value)
                  }} />
                  {searchComparisonText ? <button className="bp4-button bp4-minimal bp4-intent-primary bp4-icon-cross" aria-label="ClearSearch" onClick={() => {
                    const searchbox = document.getElementById("viewSearchBar") as HTMLInputElement
                    if (searchbox)
                      searchbox.value = ""
                    setSearchComparisonText("")
                  }}></button>
                    : <button className="bp4-button bp4-minimal bp4-intent-primary bp4-icon-arrow-right" aria-label="Search" onClick={(e) => {
                      const searchbox = document.getElementById("viewSearchBar") as HTMLInputElement
                      if (searchbox)
                        setSearchComparisonText(searchbox.value)
                    }}></button>
                  }
                </div> </>
            }
            {
              (viewDisplayed === "Dendrogram" || viewDisplayed === "Distance Matrix") &&
                <Popover2 content={
                  <Menu>
                    <MenuItem aria-label="display dendrogram by absolute allele differences" text="Absolute Allele Differences" onClick={() => onClick_dendrogramDistance("absolute_allele_differences")} />
                    <MenuItem aria-label="display dendrogram by normalized allele differences" text="Normalized Allele Differences" onClick={() => onClick_dendrogramDistance("normalized_allele_differences")} />
                  </Menu>
                } fill={true} minimal placement="bottom">
                  <Button
                    aria-label="open dendrogram view menu"
                    alignText="left"
                    fill={true}
                    rightIcon="caret-down"
                    text={`Distance: ${(() => {
                      switch (comparison.dendrogramDistance) {
                        case "absolute_allele_differences": return "Absolute Allele Differences"
                        case "normalized_allele_differences": return "Normalized Allele Differences"
                        default: return ""
                      }
                    })()
                      }`}
                  />
                </Popover2>
            }
            {
              viewDisplayed === "Dendrogram" &&
                <Slider
                  className="dendrogram-slider"
                  min={100}
                  max={300}
                  stepSize={1}
                  labelStepSize={100}
                  onChange={(val: number) => setShowZoomValue(val)}
                  labelRenderer={(val: number) => `${val}%`}
                  value={zoomValue}
                  handleHtmlProps={{ "aria-label": "zoom slider for dendrogram" }}
                />
            }
            {
              viewDisplayed === "Multiple Alignment" && <>
                <Popover2 content={
                  <Menu>
                    {multipleAlignmentList.map(i => (
                      <MenuItem key={`${i}`} text={`${i}`} onClick={() => onClick_multipleAlignmentMenuItem(i)} />
                    ))}
                  </Menu>
                } fill={true} minimal placement="bottom">
                  <Button
                    alignText="left"
                    fill={true}
                    icon="list"
                    rightIcon="caret-down"
                    text={`${multipleAlignment}`}
                  />
                </Popover2>
                <Popover2 content={
                  <Menu>
                    {processtypeList.map(i => (
                      <MenuItem key={`${i}`} text={`${i}`} onClick={() => onClick_processType(i)} />
                    ))}
                  </Menu>
                } fill={true} minimal placement="bottom">
                  <Button
                    alignText="left"
                    fill={true}
                    icon="array"
                    rightIcon="caret-down"
                    text={`Process Type: ${processType}`}
                  />
                </Popover2>
                <Popover2 content={
                  <Menu>
                    <MenuItem text={`Null`} onClick={() => onClick_blockType(null)} />
                    <MenuItem text={`Diff`} onClick={() => onClick_blockType('diff')} />
                  </Menu>
                } fill={true} minimal placement="bottom">
                  <Button
                    alignText="left"
                    fill={true}
                    icon="array"
                    rightIcon="caret-down"
                    text={`Block Type: ${blockType}`}
                  />
                </Popover2>
              </>
            }
            {
              getSelectedSampleIdsInComparison() ? <>
                {getSelectedSampleIdsInComparison().length > 0 ?
                  <>
                    <Divider />
                    <Popover2
                      minimal
                      placement="bottom-start"
                      content={
                        <Menu>
                          {
                            <>
                              <MenuItem aria-label="add sample to group" text="Add To Group" onClick={() => {
                                setShowAddToGroupDialog(true)
                              }} icon="add" />
                              {isInGroups() && <MenuItem aria-label="remove sample from group" text="Remove from Group" onClick={() => {
                                onClick_removeFromGroup()
                              }} icon="trash" />}
                              <MenuItem aria-label="add sample to new comparison" text="Add To New Comparison" onClick={() => {
                                onclick_addNewComparison()
                              }} icon="add" />
                              <MenuItem aria-label="remove sample from comparison" text="Remove from Comparison" onClick={() => {
                                if (window.confirm(`Are you sure you would like to permanently delete the selected item(s) from "${comparison?.name}" Comparison?`)) {
                                  onDelete(getSelectedSampleIdsInComparison());
                                }
                              }} icon="trash"
                              />
                            </>
                          }
                        </Menu>
                      }
                      interactionKind="click"
                    >
                      <Button aria-label="open sample actions menu" icon="properties" text={`${getSelectedSampleIdsInComparison().length} Selected`} />
                    </Popover2></> : null}
              </> : null
            }
            {Object.keys(cellEdits).length ? <Button onClick={async () => await saveCellEdits()} icon="floppy-disk" intent="primary">Save Edits</Button> : null}
          </ButtonGroup >}
      </div >
      <div className="toolBarCount">
        <span className="viewCount">
          <strong>Selected Comparison:</strong> {comparison.name} | <strong>Row Count:</strong> {searchComparisonText ? `Filtering ${getFilteredComparisonCount(searchComparisonText)} of ` : null}{currentTab.sampleData?.length} Entries
        </span>
      </div>
      <ComparisonForm />
    </div >
  }
  else
    return <></>
}

const isRegBGI = (str: string) => {
  // structure: <key>_<counter>-<genotype> = <SEQ_RegB-GI>_<1>-<GII.P16>
  return str === "SEQ_RegB-GI" || new RegExp(
    '^SEQ_RegB-GI_[a-zA-Z0-9.]+-(.*)+$'
  ).test(str)
}

const isRegBGII = (str: string) => {
  // structure: <key>_<counter>-<genotype> = <SEQ_RegB-GII>_<1>-<GII.P16>
  return str === "SEQ_RegB-GII" || new RegExp(
    '^SEQ_RegB-GII_[a-zA-Z0-9.]+-(.*)+$'
  ).test(str)
}

const isRegCGI = (str: string) => {
  // structure: <key>_<counter>-<genotype> = <SEQ_RegC-GI>_<1>-<GII.P16>
  return str === "SEQ_RegC-GI" || new RegExp(
    '^SEQ_RegC-GI_[a-zA-Z0-9.]+-(.*)+$'
  ).test(str)
}

const isRegCGII = (str: string) => {
  // structure: <key>_<counter>-<genotype> = <SEQ_RegC-GII>_<1>-<GII.P16>
  return str === "SEQ_RegC-GII" || new RegExp(
    '^SEQ_RegC-GII_[a-zA-Z0-9.]+-(.*)+$'
  ).test(str)
}


const isRegBCGI = (str: string) => {
  return str === "SEQ_RegB-C-GI" || new RegExp(
    '^SEQ_RegB-C-GI(.*)+$'
  ).test(str)
}

const isRegBCGII = (str: string) => {
  return str === "SEQ_RegB-C-GII" || new RegExp(
    '^SEQ_RegB-C-GII(.*)+$'
  ).test(str)
}

export const isMatch = (userSelection: string, name: string) => {
  return (userSelection === "SEQ_RegB-GI" && isRegBGI(name))
    || (userSelection === "SEQ_RegB-GII" && isRegBGII(name))
    || (userSelection === "SEQ_RegC-GI" && isRegCGI(name) && !isRegCGII(name))
    || (userSelection === "SEQ_RegC-GII" && isRegCGII(name))
    || (userSelection === "SEQ_RegB-C-GI" && isRegBCGI(name) && !isRegBCGII(name))
    || (userSelection === "SEQ_RegB-C-GII" && isRegBCGII(name))
}